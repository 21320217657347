<template>
  <!-- 輪播 -->
  <div id="carouselExampleIndicators" class="carousel slide mb-5" data-bs-ride="carousel">
    <ol class="carousel-indicators">
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="w-100">
          <img class="w-100" src="/images/home_banner1.jpg" />
        </div>
      </div>
      <div class="carousel-item">
        <div class="w-100">
          <img class="w-100" src="/images/home_banner2.jpg" alt="" />
        </div>
      </div>
      <div class="carousel-item">
        <div class="w-100">
          <img class="w-100" src="/images/home_banner3.jpg" alt="" />
        </div>
      </div>
      <div class="carousel-item">
        <div class="w-100">
          <img class="w-100" src="/images/home_banner4.jpg" alt="" />
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>
  </div>

  <!-- 第一區 -->
  <div class="d-md-flex   justify-content-between flex-wrap mb-5  bg-secondary">
    <div class=" w-md-45 mb-3 mb-5  myBorder">
      <img src="/Images/01.jpg" class="w-100" alt="" />
    </div>
    <div class=" w-md-45 d-flex justify-content-end align-items-end bg-secondary me-5  myBorder ">
      <!-- 讓我們來為您們服務 -->
      <div class="w-75 d-flex flex-column ">
        <h3 class="fw-bold">讓我們來為您服務</h3>
        <p class="mb-5">
          請您相信我們的專業、一束潔白玫瑰花、一取道盡感念的旋律。莊嚴、隆重或是簡簡單單。送行者們都能為您的親人量身規劃</p>

        <!-- 優質服務 -->
        <div class="d-flex align-items-start">
          <i class="fas fa-award fs-1 me-3"></i>
          <div>
            <span class="fs-5 fw-bold">優質禮儀服務</span>
            <p>
              我們是一群從遺體技術領域開始發展的專業人員，常常討論禮儀服務的意義到底是什麼？這工作的工時不固定，工作場所特殊一般人不想去，服務對象特殊一般人不想碰，有抱怨有眼淚，最後我們總結，服務始終來自於人性，我們的全程陪同、記錄並儲存，可以讓家屬內心的遺憾黯淡甚至難堪，轉化為圓滿..
            </p>
          </div>
        </div>

        <!-- 提前規劃 -->
        <div class="d-flex align-items-start">
          <i class="fas fa-clipboard fs-1 me-3"></i>
          <div>
            <span class="fs-5 fw-bold">提前規劃</span>
            <p>
              生前契約就如同保險，利用儲蓄、鎖定價格、避免通膨的方式，依個人信仰、習慣，預約未來身後事，由公司提供完整的禮儀服務，每筆預收款依法開立發票並75%交付新光銀行信託，客戶隨時可上網查詢。
            </p>
          </div>
        </div>


      </div>
    </div>
  </div>

  <!-- 第二區 -->
  <div class="d-md-flex flex-column align-items-center justify-content-center p-5  color01">
    <h2 class="text-white mb-5">價目表</h2>
    <div class="d-md-flex  ">
      <div class="d-flex flex-column justify-content-between  w-md-40 me-md-5 mb-5 bg-white myBorder">
        <div>
          <img src="/images/home-price-chinese2.jpg" class="w-100" alt="...">
          <div class="bg-white p-3">
            <h5 class="fw-bold">中式禮儀</h5>
            <p class="fw-bold">提供中式禮儀服務</p>
            <ul>
              <li>
                <div class="d-flex justify-content-between">
                  <span>圓滿契約</span>
                  <span>168,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>真情契約</span>
                  <span>128,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>簡愛契約</span>
                  <span>98,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>直接火化</span>
                  <span>58,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>聯合奠祭</span>
                  <span>38,000元</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <a href="javascript:location.href='service/chinese.html'" class="btn btn-primary  p-3">更多...</a>
      </div>

      <div class="d-flex flex-column justify-content-between  w-md-40 me-md-5 mb-5 bg-white myBorder">
        <div>
          <img src="/images/home-price-western2.jpg" class="w-100" alt="...">
          <div class="bg-white p-3">
            <h5>西式禮儀</h5>
            <p class="fw-bold">提供西式禮儀服務</p>
            <ul>
              <li>
                <div class="d-flex justify-content-between">
                  <span>圓滿契約</span>
                  <span>168,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>真情契約</span>
                  <span>128,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>簡愛契約</span>
                  <span>98,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>直接火化</span>
                  <span>58,000元</span>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <span>聯合奠祭</span>
                  <span>38,000元</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <a href="javascript:location.href='service/western.html'" class="btn btn-primary  p-3">更多...</a>
      </div>

      <div class="d-flex flex-column justify-content-between  w-md-40 me-md-5 mb-5 myBorder">
        <div>
          <img src="/images/home-price-flower.jpg" class="w-100" alt="...">
          <div class="bg-white p-3">
            <h5>奠禮花藝</h5>
            <p>提供奠禮各種花藝、莊嚴現場、撫慰家屬</p>
            <ul>
              <li>花藝(一)</li>
              <li>花藝(二)</li>
              <li>花藝(三)</li>
              <li>花藝(四)</li>
              <li>花藝(五)</li>
            </ul>
          </div>
        </div>
        <a href="javascript:location.href='product/main.html'" class="btn btn-primary  p-3">更多...</a>
      </div>

    </div>

  </div>

  <!-- 第三區 -->
  <div class="bg-white d-flex flex-column align-items-center justify-content-center p-5">
    <h3 class="fw-bold">聯絡我們</h3>
    <span @click="tel(1)" class="fs-md-4 fw-md-bold text-primary mb-1">0916-811-006 李小姐</span>
    <span @click="tel(1)" class="fs-md-4 fw-md-bold text-primary mb-3">0915-099-618 胡先生</span>
    <div class="d-flex w-100 mb-3 justify-content-between flex-wrap ">
      <input type="text" placeholder="姓名" class="form-control w-md-30 mb-3 p-3 ">
      <input type="text" placeholder="電話" class="form-control w-md-30 mb-3 p-3 ">
      <input type="text" placeholder="電子郵件" class="form-control w-md-30 mb-3 p-3 ">
    </div>
    <div class="w-100 d-flex ">
      <textarea class="form-control p-3 mb-3 " placeholder="留言內容" name="" id="" cols="30" rows="10"></textarea>
    </div>
    <button @click="call()" class="btn btn-primary form-control">傳送</button>
  </div>

  <!-- 第四區 -->
  <div class="color02 d-flex flex-column justify-content-center align-items-center p-5">

    <iframe class="mb-5"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.0922141267074!2d121.53316791500696!3d25.064863483958007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a958211108f1%3A0xd61dd0b7f3b46d58!2zMTA0OTHlj7DljJfluILkuK3lsbHljYDovrLlronooZcyNTXomZ8!5e0!3m2!1szh-TW!2stw!4v1635686292990!5m2!1szh-TW!2stw"
      width="100%" height="450" style="border:0;border-radius: 25px;" allowfullscreen="" loading="lazy"></iframe>


    <hr class="mb-5"
      style="height:2px; width:100%; border-width:0; color:rgb(255, 249, 249); background-color:rgb(248, 241, 241)">

    <div class="d-flex justify-content-around align-items-center mb-5 w-100">
      <a class="align-self-center me-3 " href="#">
        <img src="/images/logo2.png" alt="" width="90" class="d-inline-block align-text-center" />
      </a>
      <div class="d-flex flex-column text-white">
        <span class="fs-3 fw-bold mb-3">送行者有限公司</span>
        <span class="mb-3">台北市農安街255號</span>
        <span @click="tel(1)" class="fw-bold fs-6 fs-md-3 me-5 text-white mb-3">0916-811-006 李小姐</span>
        <span @click="tel(2)" class="fw-bold fs-6 fs-md-3 me-5 text-white mb-3">0915-099-618 胡先生</span>
        <img style="width:150px" src="/images/qrcode.jpg" alt="">
      </div>
    </div>
    <span class="text-white">©2021 Eternity. All rights reserved. </span>

  </div>
</template>
<script>
  import "bootstrap/js/dist/carousel";
  export default {
    data() {
      return {
        msg: "Hello Index",
      };
    },
    methods: {
      call() {
        alert('網頁功能籌備中..\n電洽：0916811006 李小姐 / 0915099618 胡先生');
      },
      tel(e) {
        switch (e) {
          case 1:
            location.href = 'tel:0916811006';
            break;
          case 2:
            location.href = 'tel:0915099618';
            break;
        }
      }
    },
  };
</script>