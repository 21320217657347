import { createApp } from 'vue'
import App from './App.vue'
//import router from './router'


import '@fortawesome/fontawesome-free/css/all.css'
import "./scss/index.scss";
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';


//createApp(App).use(router).mount('#app')
createApp(App).mount('#app')
