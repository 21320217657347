<template>
<Top></Top>
<Index></Index>
</template>


<script>
  import Top from './components/Top.vue'
  import Index from './views/Index.vue'

export default {
  components: {
    Top,
    Index
  },
  data() {
    return {
      name: process.env.VUE_APP_NAME,
    }   
  },
};
</script>

<style lang="scss">
</style>
